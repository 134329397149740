
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Timeline'
})
export default class Timeline extends Vue {
  //Data
  hover = false;
  activities = [];
  eventCode = 0;
  eventName = '';

  //Prop
  @Prop({ type: Array, required: true }) readonly items!: any[];

  get sortItmes() {
    this.items.sort(function(a, b) {
      return a['sequence'] - b['sequence'];
    });
    return this.items;
  }

  get executedEvent() {
    let executed = 0;
    this.items.forEach((item: any, index: number) => item.exec && (executed = index));
    return executed;
  }
}
