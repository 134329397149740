
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import Timeline from '@/courier/infrastructure/ui/components/order/TimelineN/Timeline.vue';
import { OrderFindByDateRange } from '@/courier/application/uses_cases/order/search/OrderFindByDateRange';
import OrderDetail from '@/courier/infrastructure/ui/components/order/OrderDetail.vue';
import SwitchEvents from '@/courier/infrastructure/ui/components/order/switch/SwitchEvents.vue';
import UpdateOrder from '@/courier/infrastructure/ui/components/order/UpdateOrder.vue';
import { CreateEventsExec } from '@/tracking/application/uses_cases/events/create/CreateEventsExec';
import i18n from '@/core/plugins/i18n';
import { OrderFindByDateRangeAndCustomerId } from '@/courier/application/uses_cases/order/search/OrderFindByDateRangeAndCustomerId';
import CreateOrderN from './CreateOrderN/CreateOrderN.vue';
import { OrderFindAllByModule } from '@/courier/application/uses_cases/order/search/OrderFindAllByModule';
import { OrderFindByClientId } from '@/courier/application/uses_cases/order/search/OrderFindByClientId';
import { OrderFindBySerial } from '@/courier/application/uses_cases/order/search/OrderFindBySerial';
import VueBarcode from 'vue-barcode';

@Component({
  name: 'OrderHistory',
  components: { UpdateOrder, SwitchEvents, OrderDetail, Timeline, CreateOrderN, VueBarcode },
  filters: {
    toCurrency(value: number) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat(`USD`);
      return formatter.format(value);
    }
  }
})
export default class AppOrderHistory extends Vue {
  @Inject(TYPES.FINDBYDATERANGE_ORDER) readonly orderFindByDateRange!: OrderFindByDateRange;
  @Inject(TYPES.ORDER_FIND_ALL_BY_MODULE) readonly orderFindAllByModule!: OrderFindAllByModule;
  @Inject(TYPES.ORDER_FIND_BY_CLIENT_ID) readonly orderFindByClientId!: OrderFindByClientId;
  @Inject(TYPES.ORDER_FIND_BY_SERIAL) readonly orderFindBySerial!: OrderFindBySerial;
  @Inject(TYPES.CREATE_EXEC_EVENTS) readonly createEventsExec!: CreateEventsExec;
  @Inject(TYPES.FINDBYDATERANGEANDCUSTOMERID_ORDER)
  readonly orderFindByDateRangeAndCustomerId!: OrderFindByDateRangeAndCustomerId;

  //Data
  target = i18n.t('general.orderhistory');
  isLoading = false;
  fullPage = true;
  customerId = '';
  trackingNmbr = '';
  selected = [];
  filter = null;
  filterOn = [];
  shippingLines = [];
  valueFrom = '';
  valueTo = '';
  arrayDateRange: any[] = [];
  orderSelected = [];
  selectOrder = [] as any;
  booleanOrder = false;
  paramsExec = false;
  typeEventOrder = '';
  loadOrders = true;
  clientId = localStorage.getItem('customerId') as string;
  searchData = document.querySelector('#searchData');

  //Refs
  $refs!: {
    dropdownOrderHistory: HTMLFormElement;
  };

  //Hook
  mounted() {
    const date = new Date();
    const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.valueFrom = new Date().getFullYear() + '-' + this.monthConcat + '-' + '01';
    this.valueTo = isoDateTime.substring(0, 10);

    this.findAllByClientId();
  }

  //Compute
  get rows() {
    return this.arrayDateRange.length;
  }

  get monthConcat() {
    return new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1);
  }

  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }

  shippingByCode(data: any, button: any, type: string) {
    const payload: any = {
      number: data.number,
      type: data.typeOrder,
      warehouse: '000',
      sw: 1
    };
    this.shippingLines = payload;
    this.typeEventOrder = type;
    this.$root.$emit('bv::show::modal', 'mdDetailOrUpdateOrder', button);
  }

  shippingByDateRange() {
    const payload: any = {
      from: this.valueFrom,
      to: this.valueTo
    };

    this.isLoading = true;
    this.orderFindByDateRange
      .execute(payload)
      .then((response: any) => {
        this.$refs.dropdownOrderHistory.hide(true);

        this.arrayDateRange = response.filter((item: any) => {
          return item.typeOrder == this.loadOrders;
        });

        const searchData = document.querySelector('#searchData');
        if (searchData) {
          this.arrayDateRange = [];
          searchData.classList.remove('search-none');
          searchData.classList.add('search-failed');
        }

        this.isLoading = false;
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  hideSearchDataFailed() {
    const searchData = document.querySelector('#searchData');
    if (searchData) {
      searchData.classList.remove('search-failed');
      searchData.classList.add('search-none');
    }
  }

  async findAllByClientId() {
    this.isLoading = true;
    const params = {
      clientId: this.clientId,
      page: 0
    };

    await this.orderFindByClientId
      .execute(params)
      .then((response: any) => {
        if (response.length > 0) {
          this.arrayDateRange = response.filter((item: any) => {
            return item.typeOrder === 'PR' || item.typeOrder === 'CT';
          });
        } else {
          const searchData = document.querySelector('#searchData');
          if (searchData) {
            this.arrayDateRange = [];
            searchData.classList.remove('search-none');
            searchData.classList.add('search-failed');
          }
        }

        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  async findBySerial() {
    this.isLoading = true;
    const params = {
      serial: this.trackingNmbr
    };

    await this.orderFindBySerial
      .execute(params)
      .then((response: any) => {
        if (!('message' in response || 'error' in response)) {
          this.arrayDateRange = [response];
          this.hideSearchDataFailed();
        } else {
          const searchData = document.querySelector('#searchData');
          if (searchData) {
            this.arrayDateRange = [];
            searchData.classList.remove('search-none');
            searchData.classList.add('search-failed');
          }
        }

        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  async findAllQuotations() {
    this.isLoading = true;
    const params = {
      clientId: this.clientId,
      page: 0
    };

    await this.orderFindByClientId
      .execute(params)
      .then((response: any) => {
        const quotations = response.filter((item: any) => {
          return item.typeOrder === 'QR';
        });

        if (quotations.length > 0) {
          this.arrayDateRange = quotations;
        } else {
          const searchData = document.querySelector('#searchData');
          if (searchData) {
            this.arrayDateRange = [];
            searchData.classList.remove('search-none');
            searchData.classList.add('search-failed');
          }
        }

        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
}
